import { BiDataContextProvider } from '@haaretz/s-use-bi';
import React from 'react';

import AtomProviders from '../AtomProviders/AtomProviders';

import ClientGlobalProviders from './ClientGlobalProviders';
import ClientState from './ClientState';

import type { AppGlobalType } from '@haaretz/s-data-structure-types';

interface GlobalProvidersProps {
  children: React.ReactNode;
  initialGlobalStore: AppGlobalType;
}

export default function GlobalProviders({
  children,
  initialGlobalStore,
}: Readonly<GlobalProvidersProps>) {
  return (
    <AtomProviders initialGlobalStore={initialGlobalStore}>
      <ClientState />
      <ClientGlobalProviders>
        <BiDataContextProvider>{children}</BiDataContextProvider>
      </ClientGlobalProviders>
    </AtomProviders>
  );
}
