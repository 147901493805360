import fork from '@haaretz/l-fork.macro';

import type { HtzImageProps } from '@haaretz/s-htz-image';

const notFound: HtzImageProps = {
  sizes: [{ from: 'l', size: '600px' }, { size: '320px' }],

  imgData: {
    __typename: 'ImageViewModel_ImageFile',
    crops: { __typename: 'ImageCrops' },
    height: fork({ default: 377, tm: 578 }),
    path: fork({
      default: '86/18/10755a694f938893b871147f89bb/1-1.png',
      tm: '69/28/5c78b44048f38593eb11aa3e0097/3-1.png',
    }),
    width: 600,
  },
  type: 'image',
  alt: 'alt',
  contentId: fork({
    default: '00000191-ad65-d4e9-a199-ad65b4850001',
    tm: '00000191-ad66-d67c-a3bd-eff7e2e80000',
  }),
  credit: null,
  widths: [320, 480, 600],
  aspect: 'full',
};

const globalError: HtzImageProps = {
  sizes: [{ from: 'l', size: '600px' }, { size: '320px' }],
  imgData: {
    __typename: 'ImageViewModel_ImageFile',
    crops: { __typename: 'ImageCrops' },
    height: 220,
    path: fork({
      default: '3e/bf/96f2c6f04e168d97e3ba15a24b6f/2-1.png',
      tm: '74/b7/823d9caa4c409f63d837b1bf0080/4.png',
    }),
    width: 320,
  },
  type: 'image',
  alt: 'alt',
  contentId: fork({
    default: '00000191-ad66-d2ec-a1f7-ef76532e0001',
    tm: '00000191-ad67-d23c-a1d3-afe7741b0001',
  }),
  credit: null,
  widths: [320, 480, 603],
  aspect: 'full',
};

const gamesError: HtzImageProps = {
  imgData: {
    __typename: 'ImageViewModel_ImageFile',
    crops: { __typename: 'ImageCrops' },
    height: 725,
    path: '36/48/2d741aec4102a10c59305a96444e/astro-fixing.png',
    width: 1201,
  },
  type: 'image',
  alt: 'alt',
  contentId: '00000193-d53f-d3c0-a3fb-f57fdb5f0001',
  credit: null,
  widths: [320, 480, 603],
  aspect: 'full',
  sizes: [{ from: 'l', size: '600px' }, { size: '320px' }],
};

const sharedImgData: HtzImageProps['imgData'] = {
  __typename: 'ImageViewModel_ImageFile',
  crops: { __typename: 'ImageCrops' },
  height: 88,
  path: '',
  width: 88,
};

const sharedGameImageProps: Omit<HtzImageProps, 'imgData' | 'contentId'> = {
  type: 'image',
  alt: 'alt',
  credit: null,
  widths: [50, 88],
  aspect: 'square',
  sizes: [{ from: 'l', size: '600px' }, { size: '320px' }],
};

const worlde: HtzImageProps = {
  ...sharedGameImageProps,
  contentId: '00000187-9e89-d6e9-a997-9e8f03ff0001',
  imgData: {
    ...sharedImgData,
    path: '4f/eb/f3e1ba744bf8b797dc6e6cd26104/wordle.png',
  },
};

const connections: HtzImageProps = {
  ...sharedGameImageProps,
  contentId: '0000018e-5b2f-d0db-a1cf-5f2fcd490001',
  imgData: {
    ...sharedImgData,
    path: '84/e6/5e422f404e1cb00940f67f925022/connections-icon.png',
  },
};

const reasonable: HtzImageProps = {
  ...sharedGameImageProps,
  contentId: '0000018c-ab48-d462-afbe-afcf766e0001',
  imgData: {
    ...sharedImgData,
    path: 'd0/bf/3aeab5624641be21f08c6daa42ed/hegionit-icon.png',
  },
};

const whoAmI: HtzImageProps = {
  ...sharedGameImageProps,
  contentId: '00000188-6ba9-de23-ad8d-fbadccf20001',
  imgData: {
    ...sharedImgData,
    path: 'eb/e7/249d868f417082446e7ae4eb73f8/pixel-icon.png',
  },
};

const whatASong: HtzImageProps = {
  ...sharedGameImageProps,
  contentId: '00000191-4ba7-d695-a7b5-dfafa1ca0001',
  imgData: {
    ...sharedImgData,
    path: '7e/e8/e1e8d45e40388bc6daa3e365de20/hit-me.png',
  },
};

const twentyQuestions: HtzImageProps = {
  ...sharedGameImageProps,
  contentId: '00000188-6bae-d2d1-afbe-7fafa7cf0001',
  imgData: {
    ...sharedImgData,
    path: '33/03/351c5da44f1785a4d056d695f7f6/20q-icon.png',
    width: 86,
    height: 86,
  },
  widths: [86],
};

const gamesImages = {
  notFound,
  globalError,
  worlde,
  connections,
  reasonable,
  whoAmI,
  whatASong,
  twentyQuestions,
  gamesError,
};

export default gamesImages;
