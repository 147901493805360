import {
  FONT_SIZE_PROP_PREFIX,
  LINE_HEIGHT_PROP_PREFIX,
  DEFAULT_CUSTOM_PROP_SELECTOR,
} from '../lib/consts';

import getMqString from './getMqString';
import getTypeProps from './getTypeProps';

import type { MediaQuery, WidthBpName } from '../lib/consts';
import type {
  TypeScaleOpts,
  CustomPropNumericValue,
  FZCustomProp,
  LHCustomProp,
  TypographyConfig,
} from '../types/typography';

const SMALLEST_STEP = -6;

export type UsageRecord<T> = Array<T> | undefined;

type UsedTypographicCustomProps = {
  [DEFAULT_CUSTOM_PROP_SELECTOR]: string;
} & Partial<Record<MediaQuery, string>>;
type Store = {
  fz: Record<FZCustomProp, CustomPropNumericValue>;
  lh: Record<LHCustomProp, CustomPropNumericValue>;
};
type TypeConfigEntries = [typeof DEFAULT_CUSTOM_PROP_SELECTOR | WidthBpName, TypeScaleOpts];

type Options = {
  typeConfig: TypographyConfig;
} & (
  | {
      fontSizeUsageRecord: UsageRecord<FZCustomProp>;
      lineHeightUsageRecord: UsageRecord<LHCustomProp>;
    }
  | { generateAll: true }
);

/**
 * Generate a CSS string of typographic custom-props based on usage records
 *
 * @param options - options object with:
 *  * a type configuration under `typeConfig`
 *  * either `generateAll: true`, to generate all typography custom props
 *    or `fontSizeUsageRecord` and `lineHeightUsageRecord`
 *
 * @public
 */

export default function generateTypographyCustomProps(
  options: Options
): UsedTypographicCustomProps {
  const usedFontSizeCustomProps =
    'generateAll' in options && options.generateAll
      ? Array.from({ length: 37 }, (_, idx) => `--fz-${idx + SMALLEST_STEP}` as FZCustomProp)
      : 'fontSizeUsageRecord' in options && options.fontSizeUsageRecord
        ? options.fontSizeUsageRecord
        : [];
  const usedLineHeightCustomProps =
    'generateAll' in options && options.generateAll
      ? Array.from({ length: 37 }, (_, idx) => `--lh-${idx + SMALLEST_STEP}` as LHCustomProp)
      : 'lineHeightUsageRecord' in options && options.lineHeightUsageRecord
        ? options.lineHeightUsageRecord
        : [];

  const usedTypographicCustomProps: UsedTypographicCustomProps = {
    [DEFAULT_CUSTOM_PROP_SELECTOR]: '',
  };

  // If possible, sort custom props
  if (FONT_SIZE_PROP_PREFIX.length === LINE_HEIGHT_PROP_PREFIX.length) {
    usedFontSizeCustomProps.sort(sortCustomProps);
    usedLineHeightCustomProps.sort(sortCustomProps);
  }

  for (const [bp, bpConfig] of Object.entries(options.typeConfig) as Array<TypeConfigEntries>) {
    const store: Store = {
      fz: {},
      lh: {},
    };
    const mq = bp === DEFAULT_CUSTOM_PROP_SELECTOR ? bp : getMqString({ from: bp });

    for (const fzVar of usedFontSizeCustomProps) {
      const step = parseInt(fzVar.slice(FONT_SIZE_PROP_PREFIX.length));
      const { fontSize, lineHeight } = getTypeProps({ step, ...bpConfig });
      store.fz[fzVar] = fontSize;

      const lhVar: LHCustomProp = `${LINE_HEIGHT_PROP_PREFIX}${step}`;
      if (usedLineHeightCustomProps.includes(lhVar)) store.lh[lhVar] = lineHeight;
    }
    // render all fontSize vars before line height vars inside breakpoint
    const cssString = `${[...Object.entries(store.fz), ...Object.entries(store.lh)]
      .map(entry => entry.join(':'))
      .join(';')};`;

    usedTypographicCustomProps[mq] =
      mq === DEFAULT_CUSTOM_PROP_SELECTOR ? cssString : `html{${cssString}}`;
  }

  return usedTypographicCustomProps;
}

function sortCustomProps(a: string, b: string) {
  const stepA = Number(a.slice(FONT_SIZE_PROP_PREFIX.length));
  const stepB = Number(b.slice(FONT_SIZE_PROP_PREFIX.length));

  return stepA - stepB;
}
