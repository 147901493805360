import { DEFAULT_CUSTOM_PROP_SELECTOR } from '../lib/consts';

/**
 * A CSS ruleset object, where each key is a selector
 * and each values a string of css declarations.
 *
 * @public
 */
export type Ruleset = { [selector: string]: string };

/**
 * Merge objects of CSS {@link Ruleset | rulesets}
 *
 * @returns
 * A css string of merge rulesets that is ready for direct usage as css
 *
 * @public
 */
export default function mergeCssRulesets(...rulesets: Ruleset[]): string {
  const processedRulesets: Record<string, string> = {};

  for (const ruleset of rulesets) {
    for (const [selector, body] of Object.entries(ruleset)) {
      const processedBody = typeof body === 'string' ? body : mergeCssRulesets(body);

      if (selector in processedRulesets) processedRulesets[selector] += processedBody;
      else processedRulesets[selector] = processedBody;
    }
  }

  return Object.entries(processedRulesets).reduce((cssString, [selector, body]) => {
    return cssString + makeCssRuleset(selector, body);
  }, '');
}

function makeCssRuleset(selector: string, body: string): string {
  const isDefaultSelector = selector === DEFAULT_CUSTOM_PROP_SELECTOR;
  const validatedSelector = isDefaultSelector
    ? // Use the html element as a selector instead of the more common `:root`
      // for decreased specificity. No need for :root since we only directly deal
      // with styling HTML documents and no other types of XML.
      'html,::backdrop'
    : selector.startsWith('@')
      ? // at-rules (media queries, supports, etc.)
        selector
      : // everything else is treated as a class name
        `.${selector},.${selector} ::backdrop`;
  return `${validatedSelector}{${body}}`;
}
